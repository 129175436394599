import React from 'react';
import StackGrid from "react-stack-grid";
import MaskedInput from 'antd-mask-input'
import { Select, Row, Col, Empty, Table, Typography, Space, Card, Button, Tooltip , Modal, Divider, Form, Popover, Input } from 'antd';
import Icon, { DollarOutlined, QuestionOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';

const { Option, OptGroup } = Select;
const { Text, Link, Paragraph } = Typography;

const Global = props => {

    const [price, setPrice] = React.useState(0);
    const [form1] = Form.useForm();

    function titleblock(n) {
        switch(n) {
          case 'unpaid': return <div className="cal-badge cal-badge-open">Ожидается оплата</div>;
          case 'pending': return <div className="cal-badge cal-badge-warning">Рассматривается</div>;
          case 'waiting_for_capture': return <div className="cal-badge cal-badge-warning">Подтверждается</div>;
          case 'succeeded': return <div className="cal-badge cal-badge-close">Оплачено</div>;
          case 'done': return <div className="cal-badge cal-badge-close">Состоялась</div>;
          case 'canceled': return <div className="cal-badge cal-badge-danger">Отменён</div>;
          default: return <div className="cal-badge">Неизвестно</div>;
        }
    }

    async function consult_add(v) {
        v['method'] = 'consult_add';
        v['phone'] = Number((v['phone']).replace(/\D+/g,""));
        await props.api(v,true);
      }

    //* Вызов окна добавления
    async function consult_push_add() {
        await props.modal('consult_add');
    }

    async function pay(id, type) {
        var v = {};
        v['payid'] = id;
        v['type'] = type;
        v['method'] = 'consult_pay';
        await props.api(v,false);
      }

    return (
        <React.Fragment>
            <Modal className="modal-mini" key="m2" title="Новая заявка" visible={props.state.modal === 'consult_add'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title title-big">Новая заявка</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <React.Fragment>
                            {'id' in props.state.da ? (
                                <Form name="form" form={form1} onFinish={consult_add} className="form" layout="vertical">
                                    { form1.setFieldsValue({ 
                                        phone:props.state.da.phone
                                    }) }
                                    <Form.Item name="type" label="Тип обращения" rules={[{ required: true, message: 'Пожалуйста, укажите тип обращения!' }]}>
                                        <Select showSearch filterOption={(input, option) =>option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            { props.state.consultations.map( (item, index) => 
                                                <OptGroup label={item.theme}>
                                                    { item.list.map( (item_two, index) => 
                                                        <Option key={item_two.id} value={item_two.id}>{item_two.price} ₽ - {item_two.name} ({item.theme})</Option>
                                                    ) }
                                                </OptGroup>
                                            ) }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name="pay" label="Способ оплаты" rules={[{ required: true, message: 'Пожалуйста, укажите тип оплаты!' }]}>
                                        <Select showSearch filterOption={(input, option) =>option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            { props.state.paytype.map( (item, index) => 
                                                <Option key={index} value={item.id}><img src={item.img} style={{maxWidth: 60, maxHeight: 32}} /> - {item.name}</Option>
                                            ) }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name="patient" label="Пациент" rules={[{ required: true, message: 'Пожалуйста, укажите пациента!' }]}>
                                        <Select showSearch filterOption={(input, option) =>option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            <Option key="no" value="0">Не указано</Option>
                                            { props.state.patients.map( (item, index) => 
                                                <Option key={index} disabled={Number(item.block)} value={item.id}>{item.nametype} - {item.name}</Option>
                                            ) }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name="theme" label="Тема" rules={[{ required: true, message: 'Пожалуйста, укажите тему!' }]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name="descr" label="Суть проблемы">
                                        <TextArea showCount maxLength={1000} rows={4} />
                                    </Form.Item>
                                    <Form.Item name="phone" label="Номер для связи" rules={[{ required: true, message: 'Пожалуйста, укажите номер телефона для связи!' }]}>
                                        <MaskedInput
                                            prefix="+"
                                            mask="7 111-111-11-11"
                                            placeholder="7 999-999-99-99"
                                            size="11"
                                            formatCharacters={{
                                            'W': {
                                                validate(char) { return /\w/.test(char ) },
                                                transform(char) { return char.toUpperCase() }
                                            }
                                            }}
                                        />
                                    </Form.Item>
                                    <Divider />
                                    <div className="fcenter">
                                        <Form.Item>
                                            <Button className="inauths" shape="round" htmlType="submit">Подтвердить</Button>
                                        </Form.Item>
                                    </div>
                                </Form>
                            ) : (
                                <Empty description="Информация о учетной записи недоступна" />
                            )}
                        </React.Fragment>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini" key="m2" title="Способ оплаты" visible={props.state.modal === 'consult_pay'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title title-big">Способ оплаты</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ? (
                        <React.Fragment>
                            {'id' in props.state.consultinfo ? (
                                <React.Fragment>
                                    <Space wrap>
                                        { props.state.paytype.map( (item, index) => 
                                            <div key={index} className="pay_button" onClick={() => pay(props.state.consultinfo.id,item.type)}>
                                                <img alt={item.type} src={item.img} />
                                            </div>
                                        ) }
                                    </Space>
                                </React.Fragment>
                            ) : (
                                <Empty description="Информация о заявке недоступна" />
                            )}
                        </React.Fragment>
                    ) : (
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    )}
                </div>
            </Modal>
            <Row className="wowload">
                <Col key="k2_clients" span={24} className="tooglecolor" >
                    { props.state.consult.length > 0 ?
                        <StackGrid
                            gutterWidth={20}
                            gutterHeight={20}
                            columnWidth={
                                props.width <= 768 ? '100%' : props.width <= 1000 ? '50%' : props.width <= 1200 ? '33.33%' : '25%'
                            }
                        >
                            {props.state.consult.map( item => 
                                <Card 
                                    title="Онлайн-консультация" 
                                    bordered={false}
                                    key={item.id}
                                >
                                    <Space direction="vertical" style={{width:'100%'}}>
                                        <Row>
                                            <Col flex="0 0 50px" style={{marginRight:10, display:'flex'}}><div className="profile_icon icon_max"><img alt="user" src='https://clinic.rus2.ru/files/img/icons/icon_cat.svg' /></div></Col>
                                            <Col flex="1">
                                                <Space direction="vertical" size="small" style={{gap:0}}>
                                                    <Text
                                                        strong
                                                        style={{
                                                            fontSize: 16,
                                                        }}
                                                    >
                                                        Врач:
                                                    </Text>
                                                    <Text>
                                                        {item.doctor_name ? 
                                                            item.doctor_name
                                                        :
                                                            'Не назначен'
                                                        }
                                                    </Text>
                                                </Space>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Space direction="vertical" size="small" style={{gap:0}}>
                                                    <Text
                                                        strong
                                                        style={{
                                                            fontSize: 16,
                                                        }}
                                                    >
                                                        Пациент:
                                                    </Text>
                                                    <Text>
                                                        {item.patient_name ?
                                                            <React.Fragment>
                                                                {item.patient_type ? 
                                                                    item.patient_type + ' ' + item.patient_name
                                                                    :
                                                                    item.patient_name
                                                                }
                                                            </React.Fragment>
                                                        : 
                                                            'не указано'
                                                        }
                                                    </Text>
                                                </Space>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Space direction="vertical" size="small" style={{gap:0}}>
                                                    <Text
                                                        strong
                                                        style={{
                                                            fontSize: 16,
                                                        }}
                                                    >
                                                        Тема:
                                                    </Text>
                                                    <Text
                                                        strong
                                                        style={{
                                                            fontSize: 14,
                                                        }}
                                                    >
                                                        {item.theme ? item.theme : 'Без темы'}
                                                    </Text>
                                                    <Text>
                                                        {item.answer ? item.answer : 'Без ответа'}
                                                    </Text>
                                                </Space>
                                            </Col>
                                        </Row>
                                        <Row gutter={16} style={{marginBottom:10}} >
                                            <Col className="gutter-row" span={12}>
                                                <Space direction="vertical" size="small" style={{gap:0}}>
                                                    <Text
                                                        strong
                                                        style={{
                                                            fontSize: 16,
                                                        }}
                                                    >
                                                        Дата:
                                                    </Text>
                                                    <Text>
                                                        {item.date > 0 ? props.lasttime(item.date,'Не отправлено','last') : <Text className="bold" type="danger">—</Text>}
                                                    </Text>
                                                </Space>
                                            </Col>
                                            <Col className="gutter-row" span={12}>
                                                <Space direction="vertical" size="small" style={{gap:0}}>
                                                    <Text
                                                        strong
                                                        style={{
                                                            fontSize: 16,
                                                        }}
                                                    >
                                                        Время:
                                                    </Text>
                                                    <Text>
                                                        {item.date > 0 ? props.lasttimee(item.date,'Не отправлено','last') : <Text className="bold" type="danger">—</Text>}
                                                    </Text>
                                                </Space>
                                            </Col>
                                        </Row>
                                        <Row gutter={16} style={{marginBottom:10}} className="row_min_vertical" >
                                            <Col className="gutter-row" span={12}>
                                                {titleblock(item.status)}
                                            </Col>
                                            <Col className="gutter-row" span={12}>
                                                <Space wrap size="middle">
                                                    <Popover placement="top" title='Суть проблемы' content={<pre>{item.descr}</pre>} trigger="click">
                                                        <Link key="a2"><div style={{background:'#6a98e7'}} className="button_circle">?</div></Link>
                                                    </Popover>
                                                    {['unpaid'].indexOf( item.status ) != -1 ? (
                                                        <Tooltip title={`Оплатить`}><Link key="a2" onClick={() => props.api({ 'method':'consult_get', 'payid':`${item.id}` }, false)}><div style={{background:'#f8485e'}} className="button_circle">₽</div></Link></Tooltip>
                                                    ) : (
                                                        null
                                                    )}
                                                </Space>
                                            </Col>
                                        </Row>
                                        <Row>
                                        </Row>
                                    </Space>

                                </Card>
                            )}
                        </StackGrid>
                    :
                        <div className="fcenter"><Empty /></div>
                    }
                    <div className="fcenter" style={{marginTop:20}}>
                        <div style={{marginBottom:20}} className="button-custom custom-red-new" onClick={() => consult_push_add()}>Заказать онлайн-консультацию</div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
};

export default Global;