import React, { useRef } from 'react';
import  ReactToPrint   from 'react-to-print';
import StackGrid from "react-stack-grid";
import { Typography, Row, Col, Space, Form, Input, Select, List, Card, Divider, Button, Empty, Tooltip, Alert } from 'antd';
import { StopOutlined, EditOutlined, CheckOutlined } from '@ant-design/icons';

const { Meta } = Card;
const { TextArea } = Input;
const { Option } = Select;
const { Link, Text, Title } = Typography;

const Global = props => {

    const [form1] = Form.useForm();
    const [form2] = Form.useForm();

    //* Выбор элемента для обновления
    async function patupd(v) {
        form2.setFieldsValue({ svid:v.id, name:v.name, descr:v.descr, price: v.price, multi: v.multi });
    }

    return (
        <React.Fragment>
            <Row className="wowload">
                <Col key="k2_clients" span={24} style={{padding:20}} className="tooglecolor" >
                    { props.state.services.length > 0 ?
                        <StackGrid
                            gutterWidth={20}
                            gutterHeight={20}
                            columnWidth={
                                props.width <= 768 ? '100%' : props.width <= 1000 ? '50%' : props.width <= 1200 ? '33.33%' : '25%'
                            }
                        >
                            {props.state.services.map( item => 
                                <Card 
                                    title={item.name} 
                                    bordered={false}
                                    key={item.id}
                                >
                                    <List
                                        locale={{ emptyText: 
                                            <Empty description='Список услуг пуст' />
                                        }}
                                        split={false}
                                        itemLayout="horizontal"
                                        dataSource={item.list}
                                        renderItem={(list,index) => (
                                            <List.Item
                                                actions={[
                                                    <div className="service_price">{list.price > 0 ? list.price+'₽' : (list.price < 0 ? 'Бесплатно' : '')}</div>
                                                ]}
                                            >
                                                <Space direction="vertical">
                                                    <pre>{list.descr}</pre>
                                                </Space>
                                            </List.Item>
                                        )}
                                    />
                                </Card>
                            )}
                        </StackGrid>
                    :
                        <div className="fcenter"><Empty /></div>
                    }
                </Col>
            </Row>
        </React.Fragment>
    )
};

export default Global;