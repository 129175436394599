import React from 'react';
import { Menu, Drawer, Layout, Divider } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

const { Sider, Header } = Layout;

const Global = props => {
    
    const showDrawer = () => {
        props.setState( {leftmenu: 1} );
    };
    const onClose = () => {
        props.setState( {leftmenu: 0} );
    };

    return (
        <React.Fragment>
            <span onClick={showDrawer} className="menubutton">
                <MenuOutlined />
            </span>
            <Drawer
                closable={false}
                placement="left"
                onClose={onClose}
                visible={props.state.leftmenu}
            >
                <div className="logomenu" />
                <Menu mode="vertical">
                    { props.state.menu.length > 0 &&
                        props.state.menu.map( list => <Menu.Item icon={props.icons(list.icon)}  key={list.id} onClick={(e) => props.menugo(e.key)}>{list.name}</Menu.Item>)
                    }
                    <Divider className="dividerwhite" />
                    <Menu.Item icon={props.icons('0')} key="0" onClick={(e) => props.menugo(e.key)}>Выход</Menu.Item>
                </Menu>
            </Drawer>
        </React.Fragment>
    )
};

export default Global;