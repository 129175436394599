import moment from 'moment';
import React from 'react';
import MaskedInput from 'antd-mask-input'
import { Result, Form, Row, Col, Typography, Space, DatePicker, Select, Divider, Button, Modal, Input, Empty } from 'antd';
import Icon, {  } from '@ant-design/icons';
import Client_View from '../../img/icons/client_view.png';

moment.locale('ru');

const { Option } = Select;
const { Paragraph, Text } = Typography;

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

//* Полное время 
function fulltime(time) {
    let date = new Date(Number(time)*1000);
    let d1 = (date.getDate()<10?'0':'') + date.getDate();
    let d2 = date.getMonth()+1;
    d2 = (d2<10?'0':'') + d2;
    let d3 = date.getFullYear();
    let d4 = (date.getHours()<10?'0':'') + date.getHours();
    let d5 = (date.getMinutes()<10?'0':'') + date.getMinutes();
    return `${d1}/${d2}/${d3} ${d4}:${d5}`;
}

const Global = props => {

    const [form] = Form.useForm();
    const [form2] = Form.useForm();

    async function profilepass(v) {
      v['method'] = 'client_pas';
      await props.api(v,true);
    }

    async function profileupd(v) {
        v['birthday'] = Date.parse(v['birthday'])/1000;
        v['phone'] = Number((v['phone']).replace(/\D+/g,""));
        v['method'] = 'client_upd';
        await props.api(v,true);
    }

    return (
        <React.Fragment>
          <Modal className="modal-mini" key="m1" title="Смена пароля" visible={props.state.modal === 'profile_pas'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title title-big">Смена пароля</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <Form {...layout} name="form" form={form} onFinish={profilepass} className="form">
                        <Form.Item name='pass' label="Пароль">
                            <Input.Password />
                        </Form.Item>
                        <Divider />
                        <div className="fcenter">
                            <Form.Item>
                                <Button className="inauths" shape="round" htmlType="submit">Сохранить</Button>
                            </Form.Item>
                        </div>
                    </Form>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
                
            </Modal>
            <Modal className="modal-mini" key="m1" title="Редактирование данных" visible={props.state.modal === 'profile_upd'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title title-big">Редактирование данных</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <React.Fragment>
                            {'id' in props.state.da ? (
                                <Form {...layout} name="form" form={form2} onFinish={profileupd} className="form">
                                    { form2.setFieldsValue({ 
                                        lastname:props.state.da.lastname,
                                        firstname:props.state.da.firstname,
                                        patronymic:props.state.da.patronymic,
                                        sex:props.state.da.sex,
                                        phone:props.state.da.phone,
                                        email:props.state.da.email,
                                        address:props.state.da.address
                                    }) }
                                    {
                                        Number(props.state.da.birthday) > 0 &&
                                        form2.setFieldsValue({ 
                                            birthday:moment(fulltime(props.state.da.birthday), "DD.MM.YYYY")
                                        })
                                    }
                                    <Form.Item name="lastname" label="Фамилия" rules={[{ required: true, message: 'Пожалуйста, укажите фамилию!' }]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name="firstname" label="Имя" rules={[{ required: true, message: 'Пожалуйста, укажите имя!' }]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name="patronymic" label="Отчество" >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name="sex" label="Пол" rules={[{ required: true, message: 'Пожалуйста, укажите пол клиента!' }]}>
                                        <Select>
                                            <Option value="0">Неизвестно</Option>
                                            <Option value="1">Мужской</Option>
                                            <Option value="2">Женский</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name="birthday" label="Дата рождения" >
                                        <DatePicker className="datepickerfull" format="DD.MM.YYYY" />
                                    </Form.Item>
                                    <Form.Item name="phone" label="Номер" rules={[{ required: true, message: 'Пожалуйста, укажите номер телефона клиента!' }]}>
                                        <MaskedInput
                                            prefix="+"
                                            mask="7 111-111-11-11"
                                            placeholder="7 999-999-99-99"
                                            size="11"
                                            formatCharacters={{
                                            'W': {
                                                validate(char) { return /\w/.test(char ) },
                                                transform(char) { return char.toUpperCase() }
                                            }
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item name="email" label="E-mail" >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name="address" label="Адрес" >
                                        <Input />
                                    </Form.Item>
                                    <Divider />
                                    <div className="fcenter">
                                        <Form.Item>
                                            <Button className="inauths" shape="round" htmlType="submit">Сохранить</Button>
                                        </Form.Item>
                                    </div>
                                </Form>
                            ) : (
                                <Empty description="Информация о учетной записи недоступна" />
                            )}
                        </React.Fragment>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Row style={{justifyContent:'center'}}>
            <Col flex="0 0 700px" className="shadowscreen profile_full" >
                <Result
                    icon={<div className="profile_icon"><img alt="user" src={Client_View} /></div>}
                    title={<div className="profile_title">Доброго времени суток,<br />{props.state.da.lastname} {props.state.da.firstname}!</div>}
                    subTitle={<div className="profile_subtitle">В данном разделе можно увидеть основную информацию о учётной записи и поменять пароль.</div>}
                />
                <div className="coldesc">
                    <Paragraph>
                        <Text
                            strong
                            style={{
                                fontSize: 16,
                            }}
                        >
                            Основная информация:
                        </Text>
                    </Paragraph>
                    <Paragraph>
                        <Row gutter={16} style={{marginBottom:10}} >
                            <Col className="gutter-row" span={12}>
                                Бонусы:
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Text>{props.state.da.cashback}</Text>
                            </Col>
                        </Row>
                        <Row gutter={16} style={{marginBottom:10}} >
                            <Col className="gutter-row" span={12}>
                                Номер телефона:
                            </Col>
                            <Col className="gutter-row" span={12}>
                                {props.state.da.phone.length > 0 ? <Text>+{props.state.da.phone}</Text> : <Text type="secondary">не указано</Text>}
                            </Col>
                        </Row>
                        <Row gutter={16} style={{marginBottom:10}} >
                            <Col className="gutter-row" span={12}>
                                Электронная почта:
                            </Col>
                            <Col className="gutter-row" span={12}>
                                {props.state.da.email.length > 0 ? <Text>{props.state.da.email}</Text> : <Text type="secondary">не указано</Text>}
                            </Col>
                        </Row>
                    </Paragraph>
                    <Divider />
                    <Paragraph>
                        <Text
                            strong
                            style={{
                                fontSize: 16,
                            }}
                        >
                            Управление:
                        </Text>
                    </Paragraph>
                    <Paragraph>
                        <Space wrap>
                          <div className="button_normal button_blue" onClick={() => props.modal('profile_pas')}>Сменить пароль</div>
                          <div className="button_normal button_blue" onClick={() => props.modal('profile_upd')}>Редактировать данные</div>
                        </Space>
                    </Paragraph>
                </div>
            </Col>
        </Row>
        </React.Fragment>   
    )
};

export default Global;