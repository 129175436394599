import React from 'react';
import MaskedInput from 'antd-mask-input';
import { Typography, Layout, Form, Input, Button, Modal, Tooltip, Divider } from 'antd';
import { EyeTwoTone, EyeInvisibleOutlined, UserOutlined, LockOutlined, InfoCircleOutlined } from '@ant-design/icons';
const { Content } = Layout;

const { Text } = Typography;

const Global = props => {

    const [form] = Form.useForm();

    //* Отправка формы пароля
    async function newpass(v) {
        v['phone'] = Number((v['phone']).replace(/\D+/g,""));
        v['method'] = 'newpass';
        await props.api(v,true);
    }

    return (
        <Layout style={{ minHeight: "100vh" }} className="bg-auth">
            <Content style={{ margin: '24px 16px 0' }} >
                <Modal key="m1" title="Восстановление пароля" visible={props.state.modal === 'newpass'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                    <Form name="form" form={form} onFinish={newpass} className="form">
                        <Form.Item name={`phone`} label='Телефон' rules={[{ required: true, message: `Пожалуйста, введите номер телефона!` }]}>
                            <MaskedInput
                                prefix="+"
                                mask="7 111-111-11-11"
                                placeholder="7 999-999-99-99"
                                size="11"
                                formatCharacters={{
                                'W': {
                                    validate(char) { return /\w/.test(char ) },
                                    transform(char) { return char.toUpperCase() }
                                }
                                }}
                            />
                        </Form.Item>
                        <Divider />
                        <div className="fcenter">
                            <Form.Item>
                                <Button type="dashed" shape="round" htmlType="submit">Запросить</Button>
                            </Form.Item>
                        </div>
                    </Form>
                </Modal>
                <div className="authpage">
                    <div className="authpageblock shadowscreen wowload">
                    <div className="logo" />
                        <p className="authtitle">Личный кабинет<br /> клиента</p>
                        <Form name="normal_login" className="login-form" initialValues={{ remember: true }} onFinish={props.f_Login} >
                            <Form.Item name="username" rules={[{ required: true, message: 'Пожалуйста, введите номер!' }]} >
                                <MaskedInput
                                    prefix={<span className="authicon"><UserOutlined style={{color:'#ffffff'}} /></span>}
                                    mask="7 111-111-11-11"
                                    className="inauth"
                                    placeholder="7 999-999-99-99"
                                    size="11"
                                    formatCharacters={{
                                    'W': {
                                        validate(char) { return /\w/.test(char ) },
                                        transform(char) { return char.toUpperCase() }
                                    }
                                    }}
                                />
                            </Form.Item>
                            <Form.Item name="password" rules={[{ required: true, message: 'Пожалуйста, введине пароль!' }]} >
                                <Input.Password prefix={<span className="authicon"><LockOutlined style={{color:'#ffffff'}} /></span>} className="inauth" type="password" placeholder="Пароль" iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
                            </Form.Item>
                            <div className="fcenter authp">
                                <Text onClick={() => props.modal('newpass')} className="newpass">Забыли пароль</Text>
                            </div>
                            <div className="fcenter">
                                <Form.Item>
                                    <Button htmlType="submit" className="inauths">Вход</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                </div>
            </Content>
        </Layout>
    )
};

export default Global;