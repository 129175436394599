import React from 'react';
import { Row, Col, Empty, Table, Typography, Space, Button, Tooltip , Modal, Divider, Form } from 'antd';
import Icon, { DollarOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';

const { Text, Link } = Typography;

const Global = props => {

    const [form1] = Form.useForm();

    const columns1 = [
        {
            title: 'Дата',
            dataIndex: 'date',
            key: 'date',
            render: (text) => (text > 0 ? props.lasttime(text,'Не отправлено','last') : <Text className="bold" type="danger">—</Text>),
            width: 100,
        },
        {
            title: 'Описание',
            dataIndex: 'text',
            key: 'text',
            render: (text) => text,
            width: 100
        },
        {
            title: 'Статус',
            dataIndex: 'type',
            key: 'type',
            render: (text) => titleblock(text),
            width: 100
        },
        {
            title: 'Количество',
            dataIndex: 'count',
            key: 'count',
            render: (text) => text,
            width: 100
        }
    ];

    function titleblock(n) {
        switch(n) {
          case '1': return <div className="cal-badge cal-badge-close">Начислено</div>;
          case '2': return <div className="cal-badge cal-badge-danger">Списано</div>;
          default: return <div className="cal-badge">Неизвестно</div>;
        }
    }

    return (
        <React.Fragment>
            <Row className="wowload">
                <Col key="k2_clients" span={24} className="shadowscreen" >
                    <Table 
                        className="table-padding"
                        locale={{ emptyText: <Empty description="Список пуст" />}} 
                        dataSource={props.state.cashback} 
                        scroll={{ x: 400, y: 400 }} 
                        pagination={false}
                        rowKey="table1" 
                        columns={columns1}
                    >
                    </Table>
                </Col>
            </Row>
        </React.Fragment>
    )
};

export default Global;