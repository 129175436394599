import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import { Popover, Space, Typography, Row, Col } from 'antd';
import {  } from '@ant-design/icons';

moment.locale('ru');
const localizer = momentLocalizer(moment);

const { Text, Title } = Typography;

class CustomEvent extends React.Component {
    constructor(props){
        super(props)
    }

    titleblock(n) {
        switch(n) {
          case '0': return <div className="cal-badge cal-badge-open">Приём открыт</div>;
          case '1': return <div className="cal-badge cal-badge-close">Приём завершен</div>;
          default: return <div className="cal-badge">Неизвестный статус</div>;
        }
    }

    render(){
        var title = <div className="fcenter" style={{marginTop:10}}><Title level={5}>{this.props.event.title.patient}</Title></div>;
        var text = 
        <Space wrap direction="vertical">
            <Space direction="horizontal" wrap>
                <Text strong>Услуга: </Text>
                <pre><Text italic>{this.props.event.title.text.name}</Text></pre>
            </Space>
            {this.props.event.title.text.text !== '' ? 
                <Space direction="horizontal" wrap>
                    <Text strong>Подробнее: </Text>
                    <pre><Text italic>{this.props.event.title.text.text}</Text></pre>
                </Space>
            : 
                null
            }
            <Space direction="horizontal" wrap>
                <Text strong>Дата: </Text>
                <Text>{moment(this.props.event.start).format("DD/MM/YYYY hh:mm")}</Text>
            </Space>
            <div className="fcenter">
                {this.titleblock(this.props.event.title.status)}
            </div>
        </Space>;

        return (
            <Popover placement="top" title={title} content={text} trigger="click" className="popover_pink">
                <div>{this.props.event.title.text.name} у {this.props.event.title.patient}</div>
            </Popover>
        );
    }
}

const Global = props => {

    var obj = {
        //* Поиск
        get list () {
            if ( props.state.calendar.length > 0 ) {
                let full = props.state.calendar;
                for (var i=0, len=full.length; i<len; i++) {
                    full[i]['start'] = new Date(full[i]['start']);
                    full[i]['end'] = new Date(full[i]['end']);
                }
                return full;            
            } else {
                return props.state.calendar;
            }
        }
    }

    return (
        <React.Fragment>
            <Row className="wowload">
                <Col key="k2_clients" span={24} className="shadowscreen" >
                    <Calendar
                        events={obj.list}
                        views={['month']}
                        components={{event:CustomEvent}}
                        localizer={localizer}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 600 }}
                        messages={{
                            next: ">",
                            previous: "<",
                            today: "Сегодня",
                            month: "Мес",
                            week: "Нед",
                            day: "День",
                            agenda: "События",
                            date: "Дата",
                            time: "Время",
                            event: "Событие"
                        }}
                    />
                </Col>
            </Row>
        </React.Fragment>
    )
};

export default Global;