import moment from 'moment';
import React, { useRef } from 'react';
import  ReactToPrint   from 'react-to-print';
import StackGrid from "react-stack-grid";
import { Typography, Row, Col, Space, Empty, Badge, Card, Table, Divider, Form, Input, Select, Modal, Button, DatePicker, Tabs, List, Popconfirm, Descriptions } from 'antd';
import { EyeOutlined, StopOutlined, SearchOutlined, CheckOutlined, PlusOutlined, CloseOutlined, PrinterOutlined, CalendarOutlined } from '@ant-design/icons';

import Client_View from '../../img/icons/client_view.png';

moment.locale('ru');

const { TabPane } = Tabs;
const { TextArea } = Input;
const { Meta } = Card;
const { Option } = Select;
const { Paragraph, Link, Title, Text } = Typography;
const Global = props => {

const [form1] = Form.useForm();
const [form2] = Form.useForm();
const [form3] = Form.useForm();
const [form4] = Form.useForm();
const [form5] = Form.useForm();
const [form6] = Form.useForm();
const [form7] = Form.useForm();
const [form8] = Form.useForm();
const [form9] = Form.useForm();
const [form10] = Form.useForm();
form2.setFieldsValue({ search: props.state.search });

const componentRef = useRef();
const componentRef2 = useRef();
const componentRef3 = useRef();

    //* Отправка формы клиенты
    async function patadd(v) {
        v['method'] = 'patient_add';
        await props.api(v,true);
    }

    //* Отправка формы пациента
    async function patientupd(v) {
        v['method'] = 'patient_upd';
        await props.api(v,true);
    }

    //* Отправка формы
    async function patientprop(v) {
        for( let value in v) {
            if (value.split('-')[0] === 'date') {
                v[value] = Date.parse(v[value])/1000;
            }
        }

        v['method'] = 'patient_prop';
        await props.api(v,true);
    }

    //* Полное время 
    function fulltime(time) {
        let date = new Date(Number(time)*1000);
        let d1 = (date.getDate()<10?'0':'') + date.getDate();
        let d2 = date.getMonth()+1;
        d2 = (d2<10?'0':'') + d2;
        let d3 = date.getFullYear();
        let d4 = (date.getHours()<10?'0':'') + date.getHours();
        let d5 = (date.getMinutes()<10?'0':'') + date.getMinutes();
        return `${d3}/${d2}/${d1} ${d4}:${d5}`;
    }

    //* Вызов окна добавления
    async function patpushadd() {
        await form5.resetFields();
        await props.modal('patients_add');
    }

    //* Вызов окна обновления
    async function patpushview(id) {
        await form6.resetFields();
        await props.api({
            'method':'patient_get',
            'patid':id
        },false);
        await props.panel('patient_view');
    }

       //* Вызов окна обновления
       async function recordpushview(id) {
        await form9.resetFields();
        await props.api({
            'method':'record_get',
            'recordid':id,
            'patid':props.state.patientinfo.profile.id
        },false);
        await props.panel('record_view');
    }

    const status = v => {
        switch(v) {
          case '0': return 'Запланирован';
          case '1': return 'Завершён';
          case '2': return 'Отменён';
          default: return 'Неизвестно';
        }
      }

      const recauthor = v => {
        switch(v) {
          case '1': return 'Автоматическая';
          case '2': return 'От врача';
          default: return 'Неизвестно';
        }
      }

    return (
        <React.Fragment>
            <Modal className="modal-mini" width="100%" zIndex="960" key="m1" title="Просмотр приёма" visible={props.state.panel.indexOf( 'record_view' ) != -1 } onOk={() => props.panel('record_view')} onCancel={() => props.panel('record_view')} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-logo"><img alt="" src={Client_View} /></div>
                    <div className="modal-mini-header-title title-big">Просмотр приёма</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <React.Fragment>
                            {Object.keys(props.state.recordinfo).length > 0 ? (
                                <div className="tworow">
                                    {'data' in props.state.recordinfo ?
                                        <div>
                                            <Divider key="d1" >Информация о приёме</Divider>
                                            <div className="print" ref={componentRef}>
                                                <Descriptions title={`Приём № ${props.state.recordinfo.data.id}`} column={1}>
                                                    <Descriptions.Item label="Врач">{props.state.recordinfo.data.emp1_name}</Descriptions.Item>
                                                    <Descriptions.Item label="Администратор">{props.state.recordinfo.data.emp2_name}</Descriptions.Item>
                                                    <Descriptions.Item label="Услуга">{props.state.recordinfo.data.s_name}</Descriptions.Item>
                                                    <Descriptions.Item label="Заметка к услуге">{props.state.recordinfo.data.s_descr}</Descriptions.Item>
                                                    <Descriptions.Item label="Подробности">{props.state.recordinfo.data.complaint}</Descriptions.Item>
                                                    <Descriptions.Item label="Дата оформления">{props.lasttime(props.state.recordinfo.data.date1,'Неизвестно когда','full')}</Descriptions.Item>
                                                    <Descriptions.Item label="Дата приёма">{props.lasttime(props.state.recordinfo.data.date2,'Неизвестно когда','full')}</Descriptions.Item>
                                                    <Descriptions.Item label="Стоимость">{props.state.recordinfo.data.price > 0 ? props.state.recordinfo.data.price+'₽' : (props.state.recordinfo.data.price < 0 ? 'Бесплатно' : 'Не указано')}</Descriptions.Item>
                                                    <Descriptions.Item label="Статус приёма">{`${status(props.state.recordinfo.data.status)}`}</Descriptions.Item>
                                                </Descriptions>
                                                <Descriptions title={`Подробности`} column={1}>
                                                    <Descriptions.Item label="Что Вас привело?">{props.state.recordinfo.data.complaint}</Descriptions.Item>
                                                    <Descriptions.Item label="Консультировались ли по этому вопросу?">{props.state.recordinfo.data.rev_2}</Descriptions.Item>
                                                    <Descriptions.Item label="Пробовали ли самостоятельно решить вопрос?">{props.state.recordinfo.data.rev_3}</Descriptions.Item>
                                                </Descriptions>
                                                <Descriptions title={`Контактная информация`} column={1}>
                                                    <Descriptions.Item label="Тип пациента">{props.state.recordinfo.data.p_typen}</Descriptions.Item>
                                                    <Descriptions.Item label="Примечание">{props.state.recordinfo.data.p_breed}</Descriptions.Item>
                                                </Descriptions>
                                            </div>
                                            <div className="fcenter">
                                                <Space wrap>
                                                    <ReactToPrint
                                                        trigger={() => <Button type="dashed" shape="round" icon={<PrinterOutlined />} >Печать</Button>}
                                                        content={() => componentRef.current}
                                                    />
                                                </Space>
                                            </div>
                                        </div>
                                    :
                                        <Empty description="Информация о приёме недоступна" />
                                    }
                                    {'rec' in props.state.recordinfo ?
                                        <div>
                                            <div className="print" ref={componentRef2}>
                                                <Divider key="d1" >Рекомендации</Divider>
                                                {props.state.recordinfo.rec.length > 0 ?
                                                    <Tabs type="card">
                                                        <TabPane tab="Все" key="0">
                                                            <List
                                                                locale={{ emptyText: 
                                                                    <Empty description='Список рекомендаций пуст' />
                                                                }}
                                                                itemLayout="horizontal"
                                                                dataSource={props.state.recordinfo.rec}
                                                                renderItem={(item,index) => (
                                                                    <List.Item>
                                                                        <Space direction="vertical">
                                                                            <Title level={5}>{recauthor(item.type)}</Title>
                                                                            <pre>{item.descr}</pre>
                                                                        </Space>
                                                                    </List.Item>
                                                                )}
                                                            />
                                                        </TabPane>
                                                        <TabPane tab="Автоматические" key="1">
                                                            <List
                                                                locale={{ emptyText: 
                                                                    <Empty description='Список рекомендаций пуст' />
                                                                }}
                                                                itemLayout="horizontal"
                                                                dataSource={props.state.recordinfo.rec.filter(function(item){return(item['type'] === '1')})}
                                                                renderItem={(item,index) => (
                                                                    <List.Item>
                                                                        <pre>{item.descr}</pre>
                                                                    </List.Item>
                                                                )}
                                                            />
                                                        </TabPane>
                                                        <TabPane tab="От врача" key="2">
                                                            <List
                                                                locale={{ emptyText: 
                                                                    <Empty description='Список рекомендаций пуст' />
                                                                }}
                                                                itemLayout="horizontal"
                                                                dataSource={props.state.recordinfo.rec.filter(function(item){return(item['type'] === '2')})}
                                                                renderItem={(item,index) => (
                                                                    <List.Item>
                                                                        <pre>{item.descr}</pre>
                                                                    </List.Item>
                                                                )}
                                                            />
                                                        </TabPane>
                                                    </Tabs>
                                                :
                                                    <Empty description="Список рекомендаций пуст" />
                                                }
                                            </div>
                                            <div className="fcenter" style={{marginTop: 20}}>
                                                    <Space>
                                                        <ReactToPrint
                                                            trigger={() => <Button type="dashed" shape="round" icon={<PrinterOutlined />} >Печать</Button>}
                                                            content={() => componentRef2.current}
                                                        />
                                                    </Space>
                                            </div>
                                        </div>
                                    :
                                        <Empty description="Информация о рекомендациях недоступна" />
                                    }
                                </div>
                            ) : (
                                <Empty description="Информация о приёме недоступна" />
                            )}
                        </React.Fragment>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini" width="100%" zIndex="950" key="m1" title="Просмотр анкеты" visible={props.state.panel.indexOf( 'patient_view' ) != -1 } onOk={() => props.panel('patient_view')} onCancel={() => props.panel('patient_view')} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-logo"><img alt="" src={Client_View} /></div>
                    <div className="modal-mini-header-title title-big">Просмотр анкеты</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <React.Fragment>
                            {Object.keys(props.state.patientinfo).length > 0 ? (
                                <div className="tworow">
                                    {'profile' in props.state.patientinfo ?
                                        <div>
                                            <Form name="form" form={form6} onFinish={patientupd} className="form">
                                                {
                                                    form6.setFieldsValue({ 
                                                        patid:props.state.patientinfo.profile.id,
                                                        name:props.state.patientinfo.profile.name,
                                                        nametype:props.state.patientinfo.profile.nametype,
                                                        breed:props.state.patientinfo.profile.breed
                                                    })
                                                }
                                                <Divider key="d1" >Информация о пациенте</Divider>
                                                <Form.Item hidden name={`patid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item  className="max400" name="name" label="Имя" rules={[{ required: true, message: 'Пожалуйста, укажите имя клиента!' }]}>
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item  className="max400" name="nametype" label="Тип" rules={[{ required: true, message: 'Пожалуйста, укажите тип пациента!' }]}>
                                                    <Select disabled showSearch filterOption={(input, option) =>option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                        { props.state.patientst.map( (item, index) => 
                                                            <Option disabled={Number(item.block)} key={index} value={item.id}>{item.name}</Option>
                                                        ) }
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item  className="max400" name="breed" label="Примечание">
                                                    <Input />
                                                </Form.Item>
                                                <div className="fcenter">
                                                    <Space wrap>
                                                        <Form.Item>
                                                            <Button type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                                        </Form.Item>
                                                        {Number(props.state.patientinfo.profile.block) === 0 ?
                                                            <Button type="dashed" danger shape="round" onClick={() => props.api({ 'method':'patient_block', 'patid':`${props.state.patientinfo.profile.id}` },false)} icon={<StopOutlined />}>Заблокировать</Button>
                                                        :
                                                            <Button type="dashed" shape="round" onClick={() => props.api({ 'method':'patient_block', 'patid':`${props.state.patientinfo.profile.id}` },false)} icon={<CheckOutlined />}>Разблокировать</Button>
                                                        }
                                                    </Space>
                                                </div>
                                                <div className="fcenter" style={{marginTop:20,display:'none'}}>
                                                    <Space wrap>
                                                        <Form.Item>
                                                            <Button type="dashed" shape="round" onClick={() => props.modal('patient_properties')}>Инфо о приёмах</Button>
                                                        </Form.Item>
                                                    </Space>
                                                </div>
                                            </Form>
                                        </div>
                                    :
                                        <Empty description="Информация о профиле недоступна" />
                                    }
                                    {'services' in props.state.patientinfo ?
                                        <div>
                                            <Divider key="d1" >Список приёмов</Divider>
                                            {props.state.patientinfo.services.length > 0 ?
                                                <Tabs type="card" className="hoverlist">
                                                    <TabPane tab="Открыт" key="1">
                                                        <List
                                                            locale={{ emptyText: 
                                                                <Empty description='Список приёмов пуст' />
                                                            }}
                                                            itemLayout="horizontal"
                                                            dataSource={props.state.patientinfo.services.filter(function(item){return(item['status'] === '0')})}
                                                            renderItem={(item,index) => (
                                                                <List.Item
                                                                    onClick={() => recordpushview(item.id)}
                                                                >
                                                                    <List.Item.Meta
                                                                        title={<React.Fragment>{item.name}</React.Fragment>}
                                                                        description={<React.Fragment>{props.lasttime(item.date2,'Неизвестно когда','full')}</React.Fragment>}
                                                                    />
                                                                </List.Item>
                                                            )}
                                                        />
                                                    </TabPane>
                                                    <TabPane tab="Завершен" key="2">
                                                        <List
                                                            locale={{ emptyText: 
                                                                <Empty description='Список приёмов пуст' />
                                                            }}
                                                            itemLayout="horizontal"
                                                            dataSource={props.state.patientinfo.services.filter(function(item){return(item['status'] === '1')})}
                                                            renderItem={(item,index) => (
                                                                <List.Item
                                                                    onClick={() => recordpushview(item.id)}
                                                                >
                                                                    <List.Item.Meta
                                                                        title={<React.Fragment>{item.name}</React.Fragment>}
                                                                        description={<React.Fragment>{props.lasttime(item.date2,'Неизвестно когда','full')}</React.Fragment>}
                                                                    />
                                                                </List.Item>
                                                            )}
                                                        />
                                                    </TabPane>
                                                    <TabPane tab="Отменен" key="3">
                                                        <List
                                                            locale={{ emptyText: 
                                                                <Empty description='Список приёмов пуст' />
                                                            }}
                                                            itemLayout="horizontal"
                                                            dataSource={props.state.patientinfo.services.filter(function(item){return(item['status'] === '2')})}
                                                            renderItem={(item,index) => (
                                                                <List.Item
                                                                    onClick={() => recordpushview(item.id)}
                                                                >
                                                                    <List.Item.Meta
                                                                        title={<React.Fragment>{item.name}</React.Fragment>}
                                                                        description={<React.Fragment>{props.lasttime(item.date2,'Неизвестно когда','full')}</React.Fragment>}
                                                                    />
                                                                </List.Item>
                                                            )}
                                                        />
                                                    </TabPane>
                                                </Tabs>
                                            :
                                            <Empty description="Список оказанных услуг пуст" />
                                            }
                                        </div>
                                    :
                                        <Empty description="Информация о услугах недоступна" />
                                    }
                                    {'rec' in props.state.patientinfo ?
                                        <div>
                                            <div className="print" ref={componentRef3}>
                                                <Divider key="d1" >Рекомендации</Divider>
                                                {props.state.patientinfo.rec.length > 0 ?
                                                    <List
                                                        locale={{ emptyText: 
                                                            <Empty description='Список рекомендаций пуст' />
                                                        }}
                                                        itemLayout="horizontal"
                                                        dataSource={props.state.patientinfo.rec}
                                                        renderItem={(item,index) => (
                                                            <List.Item>
                                                                <List.Item.Meta
                                                                    title={<React.Fragment>{item.name}</React.Fragment>}
                                                                    description={<React.Fragment><pre>{item.descr}</pre></React.Fragment>}
                                                                />
                                                            </List.Item>
                                                        )}
                                                    />
                                                :
                                                    <Empty description="Список рекомендаций пуст" />
                                                }
                                            </div>
                                            <div className="fcenter" style={{marginTop: 20}}>
                                                <Space>
                                                    <ReactToPrint
                                                        trigger={() => <Button type="dashed" shape="round" icon={<PrinterOutlined />} >Печать</Button>}
                                                        content={() => componentRef3.current}
                                                    />
                                                </Space>
                                            </div>
                                        </div>
                                    :
                                        <Empty description="Информация о рекомендациях недоступна" />
                                    }
                                </div>
                            ) : (
                                <Empty description="Информация о пациенте недоступна" />
                            )}
                        </React.Fragment>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini" mask={false} width={500} title="Карточка пациента" visible={props.state.modal === 'patient_properties'} onOk={() => props.setState( { modal:'' } )} onCancel={() => {props.setState( { modal:'' } );}} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title title-big">Карточка пациента</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <React.Fragment>
                            {'properties' in props.state.patientinfo ?
                                <React.Fragment>
                                    <Title level={5} style={{marginBottom:20}}>Оказывались ли данные услуги?</Title>
                                    <Form name="form" form={form7} onFinish={patientprop} className="form" layout="vertical">
                                        {
                                            props.state.patientinfo.properties.map(list => {
                                                return form7.setFieldsValue({ [`prop-${list.id}`]:list.value }); 
                                            })
                                        }
                                        {
                                            props.state.patientinfo.properties.map(list => {
                                                return Number(list.date) ? form7.setFieldsValue({ [`date-${list.id}`]:moment(fulltime(list.date), "YYYY-MM") }) : null
                                            })
                                        }
                                        {
                                            form7.setFieldsValue({ 
                                                patid:props.state.patientinfo.profile.id
                                            })
                                        }
                                        { props.state.patientinfo.properties.map( (item, index) => 
                                            <React.Fragment>
                                                <Form.Item key={item.id} name={`prop-${item.id}`} label={`${item.name} ${item.descr.length > 0 ? `-${item.descr}` : ``}`} >
                                                    <Select>
                                                        <Option value="0">Неизвестно</Option>
                                                        <Option value="1">Оказывалась</Option>
                                                        <Option value="2">Не оказывалась</Option>
                                                        <Option value="3">Не предлагать</Option>
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item
                                                    noStyle
                                                    shouldUpdate={(prevValues, currentValues) => prevValues[`prop-${item.id}`] !== currentValues[`prop-${item.id}`]}
                                                >
                                                    {({ getFieldValue }) =>
                                                        (getFieldValue(`prop-${item.id}`) === '1' && item.multi === '1') ? (
                                                        <Form.Item
                                                            name={`date-${item.id}`} 
                                                            label="Дата"
                                                            rules={[{ required: true }]}
                                                        >
                                                            <DatePicker
                                                                format="YYYY-MM-DD"
                                                                className="datepickerfull"
                                                            />
                                                        </Form.Item>
                                                        ) : null
                                                    }
                                                </Form.Item>
                                                <Divider />
                                            </React.Fragment>
                                        ) }
                                        <Form.Item hidden name={`patid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                            <Input />
                                        </Form.Item>
                                        <div className="fcenter">
                                            <Form.Item>
                                                <Button type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                            </Form.Item>
                                        </div>
                                    </Form>
                                </React.Fragment>
                            :
                                <Empty description="Информация о профиле недоступна" />
                            }
                        </React.Fragment>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini" width={900} title="Добавление пациента" visible={props.state.modal === 'patients_add'} onOk={() => props.setState( { modal:'' } )} onCancel={() => {props.setState( { modal:'' } );}} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title title-big">Добавление пациента</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <Form name="form" form={form5} onFinish={patadd} className="form">
                            <Form.Item  className="max400" name="name" label="Имя" rules={[{ required: true, message: 'Пожалуйста, укажите имя пациента!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item  className="max400" name="nametype" label="Тип" rules={[{ required: true, message: 'Пожалуйста, укажите тип пациента!' }]}>
                                <Select showSearch filterOption={(input, option) =>option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    { props.state.patientst.map( (item, index) => 
                                        <Option disabled={Number(item.block)} key={index} value={item.id}>{item.name}</Option>
                                    ) }
                                </Select>
                            </Form.Item>
                            <Form.Item  className="max400" name="breed" label="Примечание">
                                <Input />
                            </Form.Item>
                            <Divider />
                            <div className="fcenter">
                                <Form.Item>
                                    <Button className="inauths" shape="round" htmlType="submit">Сохранить</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Row className="wowload">
                <Col key="k2_clients" span={24} style={{padding:20}} className="tooglecolor tooglebackground" >
                    <div className="floattask anim"><span onClick={() => patpushadd()}></span></div>
                        { props.state.patients.length > 0 ?
                            <StackGrid
                                gutterWidth={20}
                                gutterHeight={20}
                                columnWidth={
                                    props.width <= 668 ? '100%' : props.width <= 1000 ? '50%' : props.width <= 1300 ? '33.33%' : '25%'
                                }
                            >
                                {props.state.patients.map( item => 
                                    <Card 
                                        title={item.name} 
                                        bordered={false}
                                        key={item.id}
                                    >
                                        <Space wrap direction="vertical" style={{marginBottom:20}}>
                                            <span><Badge color="#eb7282" /> Тип: <Text strong>{item.nametype}</Text></span>
                                            <span><Badge color="#6a98e7" /> Примечание: <Text strong>{item.breed}</Text></span>
                                        </Space>
                                        <div className="fcenter">
                                            <div className="button_normal button_blue" onClick={() => patpushview(item.id)}>Открыть</div>
                                        </div>
                                    </Card>
                                )}
                            </StackGrid>
                        :
                            <div className="fcenter"><Empty description="Список пуст" /></div>
                        }
                </Col>
            </Row>
        </React.Fragment>
    )
};

export default Global;